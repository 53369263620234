<template>
  <div class="ladies project-component">
    <div class="fullsection">
      <p>
        During my internship, I got to give my input and think critically on technical decisions for the products
        development. I got to implement new features mainly in a <strong>Vue.js environment</strong>, and collaborating with the back-end
        team, following the product roadmaps. But I also got to rework the code to optimize existing functionalities and
        be forward thinking with how the application was set to evolve in the following months. I got confronted to real
        life issues related to application deployments, and the importance of being able to rollback on code when
        features seem to be unfit to be used on the field.
      </p>
      <br>
    </div>
    <div class="half-section">
      <p>
        I was greatly received by the Ownest team, who taught me a lot and who I shared great memories with !
      </p>
    </div>

    <div class="half-section half-section-media">
      <img src="@/assets/projects/ownest/album/1.png" alt="" />
    </div>

    <div class="lesson-box">
      <h2>What did I learn ?</h2>
      <br />
      <p>
        I learned a lot about the web3 community and different applications of web3 in industries I wasn’t yet familiar
        with, I also overcame a bit of my imposter syndrome and learned learnt best practices in code and technical
        writing.
      </p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Ownest",
};
</script>
<style scoped>

</style>